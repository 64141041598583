export default class AppHelper {
  static history: any = null;

  // Window
  static getWindow = (): Window | null => {
    if (typeof window !== "undefined") {
      return window;
    }
    return null;
  };

  // HTML Document element
  static hasHtmlClass = (cls: string) => {
    return document && document.documentElement && document.documentElement.classList.contains(cls);
  };

  static addHtmlClass = (cls: string) => {
    if (document && document.documentElement && !document.documentElement.classList.contains(cls)) {
      document.documentElement.classList.add(cls);
    }
  };

  static removeHtmlClass = (cls: string) => {
    if (document && document.documentElement && document.documentElement.classList.contains(cls)) {
      document.documentElement.classList.remove(cls);
    }
  };

  // Body Element
  static hasBodyClass = (cls: string) => {
    return document && document.body && document.body.classList.contains(cls);
  };

  static addBodyClass = (cls: string) => {
    if (document && document.body && !document.body.classList.contains(cls)) {
      document.body.classList.add(cls);
    }
  };

  static removeBodyClass = (cls: string) => {
    if (document && document.body && document.body.classList.contains(cls)) {
      document.body.classList.remove(cls);
    }
  };

  // History
  static setHistory = (history: any) => {
    AppHelper.history = history;
  };

  static getHistory = (): any => {
    return AppHelper.history;
  };

  static navigate = (url: string, data?: any) => {
    if (AppHelper.history && typeof AppHelper.history.push === "function") {
      AppHelper.history.push(url, data);
      return true;
    }
    return false;
  };

  static GamePages:any = {
    "/" : "Home",
    "/instructions" : "Instructions",
    "/register" : "Registration",
    "/policy" : "Terms and Conditions",
    "/game" : "Game",
    "/result" : "Game Results",
    "/claim" : "Claim Prize",
    "/finish" : "Finish",
  };
}
