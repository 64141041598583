export const hasLS = () => {
  if (typeof localStorage === "object") {
    return true;
  }
  return false;
};

export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const setLS = (key: string, value: string | object | null) => {
  if (hasLS() && key && key.length) {
    const val: string =
      typeof value === "string" ? value : JSON.stringify(value);
    try {
      if (val === "null") {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, val);
      }
    } catch (error: any) {
      return false;
    }
  }
  return true;
};

export const getLS = (key: string) => {
  if (hasLS() && key && key.length) {
    try {
      const value: string = localStorage.getItem(key) || "null";
      if (isJson(value)) {
        return JSON.parse(value);
      }
      return value;
    } catch (error: any) {
      return null;
    }
  }
  return null;
};

export const removeLS = (key: string) => {
  if (hasLS() && key && key.length) {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (error: any) {
      return false;
    }
  }
  return false;
};

export const clearLS = () => {
  if (hasLS()) {
    try {
      localStorage.clear();
      return true;
    } catch (error: any) {
      return false;
    }
  }
  return false;
};
