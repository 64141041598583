import MobileDetect from "mobile-detect";

const DEVICE_MOCK: string =
  "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";

export interface IMobileDetect {
  mobile: () => string | null;
  phone: () => string | null;
  tablet: () => string | null;
  os: () => string | null;
  is: (device: string) => boolean;
  userAgent: () => string;
  version: (browser: string) => number;
  versionStr: (type: string) => string | null;
  match: (string: string) => boolean;
}

export interface IDevice {
  isMobile: boolean;
  isPhone: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isPhoneOrPortrait: boolean;
  mobile: string | null;
  phone: string | null;
  tablet: string | null;
  userAgent: string;
  width: number;
  height: number;
  orientation: "portrait" | "landscape";
}

export const getDevice = () => {
  const userAgent =
    window && window.navigator && window.navigator.userAgent
      ? window.navigator.userAgent
      : DEVICE_MOCK;
  const mobileDetect: IMobileDetect = new MobileDetect(userAgent);

  const ww: number = (window && window.innerWidth) || 1920;
  const hh: number = (window && window.innerHeight) || 1034;
  const orientation: "portrait" | "landscape" = hh>ww ? "portrait" : "landscape";

  const device:IDevice = {
    isMobile: !!mobileDetect.mobile(),
    isPhone: !!mobileDetect.phone(),
    isTablet: !!mobileDetect.tablet(),
    isDesktop: !mobileDetect.mobile(),
    mobile: mobileDetect.mobile(),
    phone: mobileDetect.phone(),
    tablet: mobileDetect.tablet(),
    userAgent: mobileDetect.userAgent(),
    width: ww,
    height: hh,
    orientation: orientation,
    isPhoneOrPortrait: !!mobileDetect.phone() || orientation === "portrait",
  }
  //console.log(device);
  return device;
};
