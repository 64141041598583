import classes from "./black-sign.module.scss";

export interface BlackSignProps {
  className?: string;
  children?: any;
}

const BlackSign = (props: BlackSignProps) => {
  return (
    <div className={`${classes.blackSign} ${props.className}`}>
      <div className={classes.inner}>{props.children}</div>
    </div>
  );
};

export default BlackSign;
