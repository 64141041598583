import * as React from "react";

import classes from "./audio-player.module.scss";

export interface IAudioPlayerProps {
  id: string;
  src: string;
  autoplay: boolean;
  preload?: "none" | "metadata" | "auto";
  loop?: boolean;
  onEnd?: (id: string) => void;
  onBeforeEnd?: (id: string) => void;
}

export interface IPlayerState {
  playing: boolean;
  duration: number;
  time: number;
  volume: number;
  muted: boolean;
  beforeEndSent: boolean;
}

const playerState:IPlayerState = {
  playing: false,
  duration: 0,
  time: 0,
  volume: 1,
  muted: false,
  beforeEndSent: false,
};

const AudioPlayer = React.forwardRef(
  (props: IAudioPlayerProps, audioRef: any) => {
    const onDurationChange = (e: any) => {
      //console.log("onDurationChange", e);
      playerState.duration = audioRef.current.duration;
    };

    const onTimeUpdate = (e: any) => {
      //console.log("onTimeUpdate", e);
      playerState.time = audioRef.current.currentTime;
      testBeforeEnd();
    };

    const onPlay = (e: any) => {
      //console.log("onPlay", e);
      playerState.playing = true;
      playerState.beforeEndSent = false;
    };

    const onPause = (e: any) => {
      //console.log("onPause", e);
      playerState.playing = false;
    };

    const onEnded = (e: any) => {
      //console.log("onEnded", e);
      playerState.playing = false;
      props.onEnd && props.onEnd(props.id);
    };

    const testBeforeEnd = () => {
      if(props.onBeforeEnd && !playerState.beforeEndSent) {
        const dif = playerState.duration - playerState.time;
        if(dif > 0 && dif < 0.4) {
          playerState.beforeEndSent = true;
          props.onBeforeEnd(props.id);
        }
      }
    };

    return (
      <audio
        className={classes.audioPlayer}
        ref={audioRef}
        src={props.src}
        autoPlay={props.autoplay}
        loop={props.loop}
        preload={props.preload || "none"}
        onEnded={onEnded}
        onDurationChange={onDurationChange}
        onTimeUpdate={onTimeUpdate}
        onPlay={onPlay}
        onPause={onPause}
      >
        <source src={props.src} type="audio/mp3"></source>
      </audio>
    );
  }
);

export default AudioPlayer;
