import { Md5 } from "md5-typescript";

export default class APIHelper {
  static digit = (num: Number) => {
    return (num < 10 ? "0" : "") + num.toString();
  };

  static getToken = (email: string): string => {
    // TOKEN
    const SALT: string = "SeedXg^";
    const PEPPER: string = "0!";
    const date = new Date();
    const dateStr =
      "" +
      date.getUTCFullYear() +
      APIHelper.digit(date.getUTCMonth() + 1) +
      APIHelper.digit(date.getUTCDate()) +
      "-" +
      APIHelper.digit(date.getUTCHours());
    const TOKEN: string = Md5.init(SALT + email + dateStr + PEPPER);
    return TOKEN;
  };

  static submitGame = async (data: any, isTest?: boolean): Promise<any> => {
    data.action = "game-submit";
    data.guid = APIHelper.getToken(data.email || "");
    //
    const apiUrl: string =
      (isTest ? "//sub1.fatlady.org" : "") + "/game-api.php";
    return await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((data: any) => {
        //console.log("SUCCESS", data);
        if (data.success) {
          return Promise.resolve(data);
        } else {
          return Promise.reject(
            new Error(
              "We're sorry, there has been a problem. Please try again later."
            )
          );
        }
      })
      .catch((error: Error) => {
        console.warn("FETCH ERROR", error);
        return Promise.reject(error);
      });
  };
}
