import { CSSProperties } from "react";
import PageBg from "../page-bg/page-bg";
import classes from "./page.module.scss";

export interface PageProps {
  id?: string;
  className?: string;
  style?: CSSProperties;
  children?: any;

  fullWidth?: boolean;
  noBg?: boolean;
  noBgFrame?: boolean;
  hasBgBlack?: boolean;
  greenBg?: boolean;
  gridZIndex?: number;
}

const Page = (props: PageProps) => {
  return (
    <div
      id={props.id}
      className={`page ${classes.page} ${props.className}`}
      style={props.style}
    >
      {!props.noBg && <PageBg noFrame={props.noBgFrame} hasBlack={props.hasBgBlack} greenOnly={props.greenBg} gridZIndex={props.gridZIndex} />}
      <div className={`page-inner ${classes.pageInner} ${props.fullWidth ? classes.fullWidth : ""}`}>
        {props.children}
      </div>
    </div>
  );
};

export default Page;
