import classes from "./page-bg.module.scss";
import imgBgWire from "../../../assets/bg-wire-375x266.png";

export interface PageBgProps {
  noFrame?: boolean;
  hasBlack?: boolean;
  greenOnly?: boolean;
  gridZIndex?: number;
}

const PageBg = (props: PageBgProps) => {
  return (
    <div className={`page-bg ${classes.pageBg} ${props.hasBlack ? classes.black : ""} ${props.greenOnly ? classes.green : ""}`}>
      {!props.noFrame && (
        <img src={imgBgWire} alt="bg-wire" className={`${classes.imgBgWire}`} style={{zIndex: props.gridZIndex}} />
      )}
    </div>
  );
};

export default PageBg;
