import * as React from "react";
import AudioPlayer from "../audio-player/audio-player";

export interface IAudioLoop {
  id: string;
  src: string;
  autoplay: boolean;
  listenToDocument: boolean;
  muted?: boolean;
  volume?: number;
  onInitPlay?: ()=>void;
}

const AudioLoop = (props: IAudioLoop) => {
  const player0 = React.useRef(null);
  const player1 = React.useRef(null);

  const onPlayer0Complete = (e: any) => {
    //console.log('end0');
    (player1.current as any).play();
    //
    (player0.current as any).pause();
    (player0.current as any).currentTime = 0;
  };
  const onPlayer1Complete = (e: any) => {
    //console.log('end1');
    (player0.current as any).play();
    //
    (player1.current as any).pause();
    (player1.current as any).currentTime = 0;
  };

  const mute = () => {
    (player0.current as any).muted = true;
    (player1.current as any).muted = true;
  }

  const unmute = () => {
    (player0.current as any).muted = false;
    (player1.current as any).muted = false;
  }

  React.useEffect(()=>{
    if(props.muted) {
      mute();
    } else {
      unmute();
    }
  },[props.muted]);

  React.useEffect(()=>{
    (player0.current as any).volume = props.volume;
    (player1.current as any).volume = props.volume;
  },[props.volume]);

  React.useEffect(() => {
    if (props.listenToDocument) {
      document.addEventListener("focus", onDocInit);
      document.addEventListener("click", onDocInit);
      return () => {
        document.removeEventListener("focus", onDocInit);
        document.removeEventListener("click", onDocInit);
      };
    }
  }, []);

  const onDocInit = () => {
    document.removeEventListener("focus", onDocInit);
    document.removeEventListener("click", onDocInit);
    //
    if (player0 && player0.current) {
      (player0.current as any).play();
      props.onInitPlay && props.onInitPlay();
    }
  };

  return (
    <>
      <AudioPlayer
        id={props.id + "-0"}
        key="player0"
        src={props.src}
        preload="auto"
        onBeforeEnd={onPlayer0Complete}
        autoplay={props.autoplay}
        ref={player0}
      ></AudioPlayer>
      <AudioPlayer
        id={props.id + "-1"}
        key="player1"
        src={props.src}
        preload="auto"
        onBeforeEnd={onPlayer1Complete}
        autoplay={false}
        ref={player1}
      ></AudioPlayer>
    </>
  );
};

export default AudioLoop;
