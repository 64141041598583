import React, { useState } from "react";

export const GameContext = React.createContext<IGame>({
  level: -1,
  targetPercent: 90,
  percent: 70,
  win: false,
});

export interface UserContextProps {
  children?: any;
}

export interface IGameLevelSetting {
  id: GameLevel;
  label: string;
  grainsTotal?: number;
  grainsGoodPercent?: number;
  grainsTargetPercent?: number;
  blowerDuration?: number;
  releaseRate?: number;
  speedMax?: number;
  seexXDelay?: number;
}

export enum GameLevel {
  Easy,
  Medium,
  Expert,
}

export const gameLevelSettings: IGameLevelSetting[] = [
  {
    id: GameLevel.Easy,
    label: "Easy",
    grainsTotal: 100,
    grainsGoodPercent: 70,
    grainsTargetPercent: 90,
    blowerDuration: 300,
    releaseRate: 320,
    speedMax: 10,
    seexXDelay: 10000,
  },
  {
    id: GameLevel.Medium,
    label: "Medium",
    grainsTotal: 150,
    grainsGoodPercent: 80,
    grainsTargetPercent: 90,
    blowerDuration: 300,
    releaseRate: 240,
    speedMax: 11,
    seexXDelay: 8000,
  },
  {
    id: GameLevel.Expert,
    label: "Expert",
    grainsTotal: 200,
    grainsGoodPercent: 85,
    grainsTargetPercent: 90,
    blowerDuration: 300,
    releaseRate: 160,
    speedMax: 12,
    seexXDelay: 6000,
  },
];

export interface IGame {
  level?: GameLevel;
  targetPercent?: number;
  percent?: number;
  win?: boolean;
  setLevel?: (level: number) => void;
  setGameState?: (object: any) => void;
}

const GameContextProvider = (props: UserContextProps) => {
  const [game, setGame] = useState<IGame>({
    level: -1,
  });

  const setGameState = (gameObj: any) => {
    let newGame: IGame = { ...game, ...gameObj };
    setGame(newGame);
  };

  const setLevel = (level: GameLevel) => {
    setGame({ ...game, level: level });
  };

  return (
    <GameContext.Provider
      value={{ ...game, setLevel: setLevel, setGameState: setGameState }}
    >
      {props.children}
    </GameContext.Provider>
  );
};

export default GameContextProvider;
