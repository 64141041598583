import { CSSProperties, forwardRef } from "react";
import classes from "./button.module.scss";

export interface ButtonProps {
  type?: "a" | "button" | "submit";
  id?: string;
  className?: string;
  style?: CSSProperties;
  children?: any;
  label: string;
  label2?: string;
  onClick?: (e: any) => void;
  href?: string;
  target?: string;
  color?:
    | "black"
    | "white"
    | "light-gray"
    | "mid-gray"
    | "dark-gray"
    | "light-green"
    | "mid-green"
    | "dark-green"
    | "yellow";
  textSize?: "normal" | "big" | "small";
  display?: "block" | "inline-block" | "flex" | "none";
  margins?: "default" | "vertical" | "horizontal" | "none";
  case?: "upper" | "lower" | "none";
  disabled?: boolean;
  ref?: any;
}

const Button = forwardRef((props: ButtonProps, ref:any) => {
  const onVoidClick = (e: any) => {
    e.preventDefault();
    //console.log('void click');
    if (typeof props.onClick === "function") {
      props.onClick(e);
    }
    return false;
  };

  return props.type === "button" || props.type === "submit" ? (
    <button
      ref={ref}
      type={props.type}
      value={props.label}
      id={props.id}
      className={`${classes.button} ${
        classes["clr-" + (props.color || "black")]
      } ${classes["disp-" + (props.display || "inline-block")]} ${
        classes["mg-" + (props.margins || "default")]
      } ${classes["size-" + (props.textSize || "normal")]} ${
        props.label2 ? "has-label2" : ""
      } ${props.disabled ? classes.disabled : ""} ${
        classes["case-" + (props.case || "upper")]
      } ${props.className || ""}`}
      style={props.style}
      onClick={typeof props.onClick === "function" ? props.onClick : undefined}
    >
      {props.label && <span className={classes.label}>{props.label}</span>}
      {props.label2 && <span className={classes.label2}>{props.label2}</span>}
      {props.children && (
        <span className={classes.children}>{props.children}</span>
      )}
    </button>
  ) : (
    <a
      ref={ref}
      id={props.id}
      className={`${classes.button} ${
        classes["clr-" + (props.color || "black")]
      } ${classes["disp-" + (props.display || "inline-block")]} ${
        classes["mg-" + (props.margins || "default")]
      } ${classes["size-" + (props.textSize || "normal")]}  ${
        props.label2 ? classes["has-label2"] : ""
      } ${props.disabled ? classes.disabled : ""} ${
        classes["case-" + (props.case || "upper")]
      } ${props.className || ""}`}
      style={props.style}
      href={props.href || ""}
      target={props.target || "_self"}
      onClick={
        props.href && props.href.length
          ? typeof props.onClick === "function"
            ? props.onClick
            : undefined
          : onVoidClick
      }
    >
      {props.label && <span className={classes.label}>{props.label}</span>}
      {props.label2 && <span className={classes.label2}>{props.label2}</span>}
      {props.children && (
        <span className={classes.children}>{props.children}</span>
      )}
    </a>
  );
});

export default Button;
