import React, { useEffect, useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import UserContextProvider from "./components/contexts/user-context";
import GameContextProvider from "./components/contexts/game-context";

import WebsiteMain from "./components/layout/website/website";
import PageHome from "./components/pages/home/page-home";

import classes from "./App.module.scss";
import "./styles/reset.scss";
import "./styles/fonts.scss";
import "./styles/sizes.scss";
import AppHelper from "./helpers/app-helper";
import PageGame from "./components/pages/game/page-game";
import PageRegister from "./components/pages/register/page-register";
import PageResult from "./components/pages/result/page-result";
//import PageFinish from "./components/pages/finish/page-finish";
import PageInstructions from "./components/pages/instructions/page-instructions";
import PagePolicy from "./components/pages/policy/page-policy";
//import PageClaim from "./components/pages/claim/page-claim";
import ConversionHelper from "./helpers/conversion-helper";

const relPath: string = process.env["REL_PATH"] || "";
const history: any = createBrowserHistory();

const App = () => {
  useEffect(() => {
    AppHelper.setHistory(history);
    history.listen((location: Location) => {
      //console.log("*", location);
      const path: string = location.pathname;
      const title: string = AppHelper.GamePages[path] || "";
      ConversionHelper.gtmTrackPageView(path, title);
    });
  }, []);

  const [prevScroll, setPrevScroll] = useState<number>(0);

  const isBrowser = () => {
    if (typeof window !== "undefined") {
      return true;
    }
    return false;
  };

  // RESIZE
  const initResize = () => {
    isBrowser() && window.addEventListener("resize", onPageResize);
    isBrowser() && onPageResize(null);
  };

  const onPageResize = (e: any) => {
    //console.log("w:", window.innerWidth);
    //console.log("h:", window.innerHeight);
    onPageScroll(e);
    // VH
    let vh = 0.01 * window.innerHeight;
    document.documentElement.style.setProperty("--vh", vh + "px");
  };

  // SCROLL
  const initScroll = () => {
    isBrowser() && window.addEventListener("scroll", onPageScroll);
  };

  const onPageScroll = (e: any) => {
    //console.log("t:", document.documentElement.scrollTop);
    let winH: number = window.innerHeight;
    let scrollPos: number = document.documentElement.scrollTop;
    let scrollAll: number = document.documentElement.scrollHeight - winH;
    if (prevScroll !== scrollPos) {
      if (scrollPos === 0) {
        AppHelper.removeBodyClass("scrolled");
        AppHelper.addBodyClass("scroll-top");
      } else {
        AppHelper.removeBodyClass("scroll-top");
        AppHelper.addBodyClass("scrolled");
        if (scrollPos === scrollAll) {
          AppHelper.addBodyClass("scroll-bottom");
        } else {
          AppHelper.removeBodyClass("scroll-bottom");
        }
      }
      //
      if (scrollPos > prevScroll) {
        AppHelper.removeBodyClass("scrolled-up");
        AppHelper.addBodyClass("scrolled-down");
      } else {
        AppHelper.removeBodyClass("scrolled-down");
        AppHelper.addBodyClass("scrolled-up");
      }
      setPrevScroll(scrollPos);
    }
  };

  useEffect(() => {
    initResize();
    initScroll();
    AppHelper.removeHtmlClass("unscroll");
  }, []);

  return (
    <div id="site" className={classes.app}>
      <React.StrictMode>
        <UserContextProvider>
          <GameContextProvider>
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path={relPath + "/"}
                  render={(props) => (
                    <WebsiteMain {...props} hideHeader={true} hideFooter={true}>
                      <PageHome {...props} />
                    </WebsiteMain>
                  )}
                ></Route>
                <Route
                  exact
                  path={relPath + "/instructions"}
                  render={(props) => (
                    <WebsiteMain {...props} hideHeader={true} hideFooter={true}>
                      <PageInstructions {...props} />
                    </WebsiteMain>
                  )}
                ></Route>
                <Route
                  exact
                  path={relPath + "/register"}
                  render={(props) => (
                    <WebsiteMain {...props} hideHeader={true} hideFooter={true}>
                      <PageRegister {...props} />
                    </WebsiteMain>
                  )}
                ></Route>
                <Route
                  exact
                  path={relPath + "/game"}
                  render={(props) => (
                    <WebsiteMain {...props} hideHeader={true} hideFooter={true}>
                      <PageGame {...props} />
                    </WebsiteMain>
                  )}
                ></Route>
                <Route
                  exact
                  path={relPath + "/results"}
                  render={(props) => (
                    <WebsiteMain {...props} hideHeader={true} hideFooter={true}>
                      <PageResult {...props} />
                    </WebsiteMain>
                  )}
                ></Route>
                {/*<Route
                  exact
                  path={relPath + "/finish"}
                  render={(props) => (
                    <WebsiteMain {...props} hideHeader={true} hideFooter={true}>
                      <PageFinish {...props} />
                    </WebsiteMain>
                  )}
                  ></Route>*/}
                {/*<Route
                  exact
                  path={relPath + "/claim"}
                  render={(props) => (
                    <WebsiteMain {...props} hideHeader={true} hideFooter={true}>
                      <PageClaim {...props} />
                    </WebsiteMain>
                  )}
                  ></Route>*/}
                <Route
                  exact
                  path={relPath + "/policy"}
                  render={(props) => (
                    <WebsiteMain {...props} hideHeader={true} hideFooter={true}>
                      <PagePolicy {...props} />
                    </WebsiteMain>
                  )}
                ></Route>
              </Switch>
            </Router>
          </GameContextProvider>
        </UserContextProvider>
      </React.StrictMode>
    </div>
  );
};

export default App;
