import { CSSProperties, useEffect, useRef, useState } from "react";
import Button from "../../../ui/button/button";
import classes from "./seedx-screen.module.scss";

import imgHero from "../../../../assets/seedx-hero-362x420.svg";
import imgTitles from "../../../../assets/seedx-titles-240x90.svg";

export interface BoxProps {
  id?: string;
  className?: string;
  style?: CSSProperties;
  onClose: () => void;
}

const SeedXScreen = (props: BoxProps) => {
  const [visible, setVisible] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
      setTimeout(() => {
        setEnabled(true);
        if (buttonRef && buttonRef.current) {
          (buttonRef.current as any).focus();
        }
      }, 1000);
    }, 50);
  }, []);

  const onCloseClick = (e: any) => {
    setEnabled(false);
    setVisible(false);
    setTimeout(() => {
      props.onClose();
    }, 500);
  };

  return (
    <div className={`${classes.seedXScreen} ${visible ? classes.open : ""}`}>
      <div className={`${classes.inner} page-inner`}>
        <div className={classes.hero}>
          <img src={imgHero} alt="hero" />
        </div>
        <div className={classes.content}>
          <div className={classes.black}></div>
          <div className={classes.titles}>
            <img src={imgTitles} alt="Seed-X The Germinator" />
          </div>
          <h3>BONUS STRENGTH</h3>
          <div className={classes.text}>
            We&apos;ve highlighted the non germinating seeds, for a limited
            time, so hurry...!
          </div>
          <div className={classes.cta}>
            <Button
              ref={buttonRef}
              label="Got it"
              color="white"
              textSize="big"
              display="flex"
              margins="none"
              onClick={onCloseClick}
              disabled={!enabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeedXScreen;
