const EmptyError: string = "Field is empty";
const NameError: string = "Name is missing";
const FullNameError: string = "Full name is required";
const EmailError: string = "Email is incorrect";

const getError = (errorStr: string, label?: string) => {
  if (label) {
    return (
      label.substring(0, 1).toUpperCase() +
      label.substring(1) +
      " " +
      errorStr.toLowerCase()
    );
  }
  return errorStr;
};

export const validateEmpty = (
  value: string | number | undefined,
  label?: string
) => {
  return typeof value !== "undefined" && value.toString().length > 0
    ? false
    : getError(EmptyError, label);
};

export const validateName = (
  value: string | number | undefined,
  label?: string
) => {
  return typeof value === "string" && value && value.length > 0
    ? false
    : NameError;
};

export const validateFullName = (
  value: string | number | undefined,
  label?: string
) => {
  const spacePos: number = value ? value.toString().indexOf(" ") : -1;
  return typeof value === "string" &&
    value &&
    value.length > 3 &&
    spacePos > 0 &&
    spacePos < value.length - 1
    ? false
    : FullNameError;
};

export const validateEmail = (
  value: string | number | undefined,
  label?: string
) => {
  const regex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return typeof value === "string" &&
    value &&
    value.length > 5 &&
    regex.test(value.toLowerCase())
    ? false
    : EmailError;
};
