import { useEffect, useRef, useState } from "react";
import {
  GameLevel,
  gameLevelSettings,
  IGameLevelSetting,
} from "../../../contexts/game-context";
import classes from "./game.module.scss";

import imgBgWire from "../../../../assets/bg-wire-375x266.png";
import imgTunnels from "../../../../assets/bg-tunnels-377x189.svg";
import imgButtonUp from "../../../../assets/button-red-up-129x85.svg";
import imgButtonDown from "../../../../assets/button-red-down-129x58.svg";
import imgArrowDown from "../../../../assets/arrows-down-41x90.svg";
import imgArrowDown2 from "../../../../assets/arrows-down2-41x90.svg";
import imgGrain from "../../../../assets/grain-brown-42x45.png";
import imgBlower from "../../../../assets/blower-175x56.svg";
import imgBlowAir from "../../../../assets/blower-air-205x61.png";
import imgScoreBoard from "../../../../assets/score-board-233x88.svg";
import imgBatchIcon from "../../../../assets/icon-batch-84x82.svg";
//import imgSeedXLogo from "../../../../assets/Seed-X-logo-97x27.svg";
import SeedXScreen from "../seedx/seedx-screen";
import { getDevice, IDevice } from "../../../../helpers/deviceHelper";

////////////////////////////////////////////////////
// ENUMS
enum GameStates {
  INIT,
  ON,
  COMPLETE,
}
enum GrainStates {
  TOP,
  BOARD,
  GERMINATE,
  NONGERMINATE,
}

////////////////////////////////////////////////////
// INTERFACES
interface IGameSetting {
  gameWidth: number;
  gameHeight: number;
  gameFrameRate: number;
  gameFrameDelay: number;
  gameTi: any;
  pcStart: number;
  pcEnd: number;
  pcLeft: number;
  pcRight: number;
  pcBlower: number;
  pxStart: number;
  pxEnd: number;
  pxLeft: number;
  pxRight: number;
  pxBlower: number;
  grainsTotal: number;
  grainsGoodPercent: number;
  grainsTargetPercent: number;
  grainSize: number;
  blowerSize: number;
  blowerDuration: number;
  blowerTi: any;
  releaseRate: number;
  releaseTi: any;
  speedInit: number;
  speedMax: number;
  speedBlow: number;
  accY: number;
  accX: number;
  seedXDelay: number;
}

interface IGrain {
  id: string;
  germinate: boolean;
  state: GrainStates;
  ignore: Boolean;
  posX: number;
  posY: number;
  vX: number;
  vY: number;
  element: any;
}

interface IGamePositions {
  top: IGrain[];
  board: IGrain[];
  germinate: IGrain[];
  nonGerminate: IGrain[];
}

interface IGameState {
  state: GameStates;
  grains: IGrain[];
  positions: IGamePositions;
  isBlowing: boolean;
}

////////////////////////////////////////////////////
// CONSTANTS
const gameSettings: IGameSetting = {
  // game dimentions
  gameWidth: 0,
  gameHeight: 0,
  // frame rate
  gameFrameRate: 25,
  gameFrameDelay: Math.round(1000 / 30),
  gameTi: -1,
  // positions PC
  pcStart: 0,
  pcEnd: 100,
  pcLeft: 27,
  pcRight: 73,
  pcBlower: 30,
  // positions PX
  pxStart: 0,
  pxEnd: 0,
  pxLeft: 0,
  pxRight: 0,
  pxBlower: 0,
  // grains
  grainsTotal: 100,
  grainsGoodPercent: 70,
  grainsTargetPercent: 90,
  grainSize: 20,
  // blower
  blowerSize: 40,
  blowerDuration: 300,
  blowerTi: -1,
  // release speed rate (milisecs)
  releaseRate: 150, // 150-500
  releaseTi: -1,
  // grain speed rate (px)
  speedInit: 1,
  speedMax: 10, //8 - 12
  speedBlow: 10,
  // grain acceleration
  accX: 0.95,
  accY: 1.1,
  // SeedX delay
  seedXDelay: 8000,
};

const gameState: IGameState = {
  state: GameStates.INIT,
  grains: [],
  positions: {
    top: [],
    board: [],
    germinate: [],
    nonGerminate: [],
  },
  isBlowing: false,
};

//const GAME_SEEDX_DELAY: number = 8000;
const GAME_TIMEOUT_DELAY: number = 3000;
const CALC_SIFTED_GERMINATE: boolean = true;
const PAUSABLE_GAME: boolean = true;

////////////////////////////////////////////////////
// GAME FUNCTION COMPONENT
const Game = ({ level, onComplete }: any) => {
  const gameContainer: any = useRef(null);
  const gameBoard: any = useRef(null);
  const [successPc, setSuccessPc] = useState(70);
  const [isShowBlow, setIsShowBlow] = useState(false);
  const [isSeedXActive, setIsSeedXActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [grainsLeft, setGrainsLeft] = useState(gameSettings.grainsTotal);
  const [isGameOver, setIsGameOver] = useState(false);
  const [showSeedX, setShowSeedX] = useState(false);

  // INIT GAME
  useEffect(() => {
    initLevel(level >= 0 ? level : 0);
    setGrainsLeft(gameSettings.grainsTotal);
    setSuccessPc(gameSettings.grainsGoodPercent);
    setTimeout(() => {
      setGameSizes();
      createGrains();
      setCountDown();
    }, 100);
    return () => {
      unsetKeyListeners();
    };
  }, []);

  const initLevel = (level: GameLevel) => {
    let setting: IGameLevelSetting = gameLevelSettings[level];
    if (setting) {
      gameSettings.grainsTotal = setting.grainsTotal
        ? setting.grainsTotal
        : gameSettings.grainsTotal;
      gameSettings.grainsGoodPercent = setting.grainsGoodPercent
        ? setting.grainsGoodPercent
        : gameSettings.grainsGoodPercent;
      gameSettings.grainsTargetPercent = setting.grainsTargetPercent
        ? setting.grainsTargetPercent
        : gameSettings.grainsTargetPercent;
      gameSettings.blowerDuration = setting.blowerDuration
        ? setting.blowerDuration
        : gameSettings.blowerDuration;
      gameSettings.releaseRate = setting.releaseRate
        ? setting.releaseRate
        : gameSettings.releaseRate;
      gameSettings.speedMax = setting.speedMax
        ? setting.speedMax
        : gameSettings.speedMax;
      gameSettings.seedXDelay = setting.seexXDelay
        ? setting.seexXDelay
        : gameSettings.seedXDelay;
      //
      const device: IDevice = getDevice();
      //console.log('isPhoneOrPortrait',device.isPhoneOrPortrait);
      gameSettings.grainSize = device.isPhoneOrPortrait ? 20 : 30;
      gameSettings.blowerSize = device.isPhoneOrPortrait ? 40 : 60;
      gameSettings.speedMax = device.isPhoneOrPortrait ? 10 : 15;
      gameSettings.speedBlow = Math.round(device.width / 37.5);
      //console.log('speedBlow',gameSettings.speedBlow);
    }
  };

  const setGameSizes = () => {
    if (gameContainer && gameContainer.current) {
      // game dimentions
      gameSettings.gameWidth = gameContainer.current.offsetWidth;
      gameSettings.gameHeight = gameContainer.current.offsetHeight;
      // positions
      gameSettings.pxStart =
        (gameSettings.gameHeight * gameSettings.pcStart) / 100;
      gameSettings.pxEnd = (gameSettings.gameHeight * gameSettings.pcEnd) / 100;
      gameSettings.pxLeft =
        (gameSettings.gameWidth * gameSettings.pcLeft) / 100;
      gameSettings.pxRight =
        (gameSettings.gameWidth * gameSettings.pcRight) / 100;
      gameSettings.pxBlower =
        (gameSettings.gameHeight * gameSettings.pcBlower) / 100;
      //console.log("gameSettings",gameSettings);
    }
  };

  const createGrains = () => {
    let arr: IGrain[] = [];
    for (let i = 0; i < gameSettings.grainsTotal; i++) {
      arr.push({
        id: "grain" + i,
        germinate:
          i <
          Math.round(
            (gameSettings.grainsGoodPercent * gameSettings.grainsTotal) / 100
          ),
        state: GrainStates.TOP,
        ignore: false,
        posX: gameSettings.pxLeft,
        posY: gameSettings.pxStart - gameSettings.grainSize / 2,
        vX: 0,
        vY: gameSettings.speedInit,
        element: null,
      });
    }
    //console.log(arr);
    let grainArray: IGrain[] = [];
    do {
      let index: number = Math.floor(Math.random() * arr.length);
      index = index === arr.length ? 0 : index;
      let grain: IGrain = arr[index];
      arr.splice(index, 1);
      grainArray.push(grain);
    } while (arr.length);
    //console.log(grainArray);
    gameState.grains = grainArray;
    gameState.positions.top = [...grainArray];
    //console.log(gameState);
  };

  const setCountDown = () => {
    setTimeout(() => drawLoading(3), 1000);
    setTimeout(() => drawLoading(2), 2000);
    setTimeout(() => drawLoading(1), 3000);
    setTimeout(() => startGame(), 4000);
  };

  const drawLoading = (num: number) => {
    if (gameBoard && gameBoard.current) {
      let digit: any = document.createElement("div");
      digit.id = "digit" + num;
      digit.className = classes.digit;
      digit.innerText = num;
      gameBoard.current.appendChild(digit);
    }
  };

  const startGame = () => {
    //console.log("startGame");
    if (gameContainer && gameContainer.current) {
      setIsDisabled(false);
    }
    if (gameBoard && gameBoard.current) {
      gameBoard.current.removeChild(document.getElementById("digit3"));
      gameBoard.current.removeChild(document.getElementById("digit2"));
      gameBoard.current.removeChild(document.getElementById("digit1"));
      setTimeout(() => startEngine(), 500);
      setTimeout(() => initSeedX(), gameSettings.seedXDelay + 500);
    }
    setKeyListeners();
  };

  const setKeyListeners = () => {
    unsetKeyListeners();
    document.addEventListener("keydown", onKeyEvent);
  };

  const unsetKeyListeners = () => {
    document.removeEventListener("keydown", onKeyEvent);
  };

  const onKeyEvent = (e: any) => {
    //console.log(e.keyCode);
    switch (e.keyCode) {
      case 32:
        // Space
        e.preventDefault();
        onButtonDown(null);
        break;
      case 13:
        // Enter
        break;
      case 80:
        // P
        if (PAUSABLE_GAME) {
          stopEngine();
        }
        break;
    }
  };

  const startEngine = () => {
    stopEngine();
    gameSettings.gameTi = setInterval(
      onEngineTick,
      gameSettings.gameFrameDelay
    );
    gameSettings.releaseTi = setInterval(
      onReleaseTick,
      gameSettings.releaseRate
    );
  };

  const stopEngine = () => {
    clearInterval(gameSettings.gameTi);
    gameSettings.gameTi = -1;
    clearInterval(gameSettings.releaseTi);
    gameSettings.releaseTi = -1;
  };

  const onEngineTick = () => {
    posGrains();
  };

  const initSeedX = () => {
    //console.log("initSeedX");
    stopEngine();
    setShowSeedX(true);
  };

  const onSeedXClose = () => {
    //console.log("onSeedXClose");
    setShowSeedX(false);
    setIsSeedXActive(true);
    startEngine();
  };

  const onReleaseTick = () => {
    // Create / Draw Grain
    if (gameBoard && gameBoard.current) {
      if (gameState.positions.top.length) {
        // Create element
        let grain: IGrain = gameState.positions.top[0];
        gameState.positions.top.splice(0, 1);
        setGrainsLeft(gameState.positions.top.length);
        //
        let grainElem: any = document.createElement("div");
        grainElem.id = grain.id;
        grainElem.className =
          classes.grain +
          " " +
          (grain.germinate ? classes.germinate : classes.nonGerminate);
        let grainImg: any = document.createElement("img");
        grainImg.src = imgGrain;
        grainImg.alt = "Grain";
        grainElem.appendChild(grainImg);

        grain.element = grainElem;
        grain.state = GrainStates.BOARD;
        gameState.positions.board.push(grain);
        gameBoard.current.appendChild(grainElem);
        posGrain(grain, true);
      }
    }
  };

  const posGrains = () => {
    let drawableGrains = gameState.positions.board;
    drawableGrains.forEach((grain: IGrain) => {
      posGrain(grain);
    });
  };

  const posGrain = (grain: IGrain, noChange: boolean = false) => {
    if (gameBoard && gameBoard.current) {
      if (grain.element && grain.state === GrainStates.BOARD) {
        if (!noChange) {
          // acc
          grain.vX *= gameSettings.accX;
          //grain.vX = Math.min(grain.vX, gameSettings.speedMax);
          grain.vY *= gameSettings.accY;
          grain.vY = Math.min(grain.vY, gameSettings.speedMax);
          // pos
          grain.posX += grain.vX;
          grain.posY += grain.vY;
          if (grain.posX >= gameSettings.pxRight) {
            grain.vX = 0;
            grain.posX = gameSettings.pxRight;
          }
          //
          // test blow
          if (gameState.isBlowing && grain.vX === 0) {
            if (
              grain.posY >=
                gameSettings.pxBlower - gameSettings.blowerSize / 2 &&
              grain.posY <= gameSettings.pxBlower + gameSettings.blowerSize / 2
            ) {
              //console.log('speedBlow',gameSettings.speedBlow);
              grain.vX = gameSettings.speedBlow;
              grain.vY = Math.max(grain.vY - gameSettings.speedBlow, 2);
              grain.ignore = true;
              if (CALC_SIFTED_GERMINATE) {
                grain.ignore = !grain.germinate;
              }
              //
              calculate();
            }
          }
        }
        //
        //console.log(grain.id,grain.posX,grain.posY);
        grain.element.style.transform =
          "translate(" + grain.posX + "px," + grain.posY + "px)";
        //
        // remove
        if (grain.posY >= gameSettings.pxEnd + gameSettings.grainSize / 2) {
          let germinated: boolean = grain.posX === gameSettings.pxLeft;
          gameBoard.current.removeChild(grain.element);
          grain.element = null;
          grain.state = germinated
            ? GrainStates.GERMINATE
            : GrainStates.NONGERMINATE;
          let index: number = gameState.positions.board.indexOf(grain);
          gameState.positions.board.splice(index, 1);
          if (germinated) {
            gameState.positions.germinate.push(grain);
            //
            calculate();
          } else {
            gameState.positions.nonGerminate.push(grain);
          }
          //console.log("removed ", grain.id);
          // last
          if (
            gameState.positions.top.length === 0 &&
            gameState.positions.board.length === 0
          ) {
            stopEngine();
            setIsGameOver(true);
            setTimeout(onGameComplete, GAME_TIMEOUT_DELAY);
          }
        }
      }
    }
  };

  const calculate = () => {
    let all: number = 0;
    let germinate: number = 0;
    gameState.grains.forEach((grain: IGrain) => {
      if (!grain.ignore) {
        all++;
        if (CALC_SIFTED_GERMINATE) {
          if (grain.germinate && grain.state !== GrainStates.NONGERMINATE) {
            germinate++;
          }
        } else {
          if (grain.germinate) {
            germinate++;
          }
        }
      }
    });
    const pc: number = Math.round((100 * germinate) / all);
    //console.log("germinate:", pc);
    setSuccessPc(pc);
    return pc;
  };

  const onButtonDown = (e: any) => {
    if (!gameState.isBlowing) {
      clearTimeout(gameSettings.blowerTi);
      gameState.isBlowing = true;
      setIsShowBlow(true);
      //console.log("blow");
      gameSettings.blowerTi = setTimeout(() => {
        gameState.isBlowing = false;
        setIsShowBlow(false);
      }, gameSettings.blowerDuration);
    }
  };

  const onButtonUp = (e: any) => {};

  const onSeedXClick = (e: any) => {
    setIsSeedXActive(!isSeedXActive);
  };

  const onGameComplete = () => {
    unsetKeyListeners();
    if (typeof onComplete === "function") {
      onComplete(calculate(), gameSettings.grainsTargetPercent);
    }
  };

  return (
    <div
      id="game"
      className={`${classes.game} ${isDisabled ? classes.disabled : ""} ${
        isSeedXActive ? classes.seedXActive : ""
      } ${isGameOver ? classes.gameOver : ""}`}
      ref={gameContainer}
      onContextMenu={(e: any) => {
        e.preventDefault();
        return false;
      }}
    >
      <div className={classes.bg}></div>
      <img
        src={imgBgWire}
        alt="bg-wire"
        className={`${classes.imgAsset} ${classes.imgBgWire}`}
      />
      <div className={`${classes.arrowsDown}`}>
        <img
          src={imgArrowDown}
          alt="arrows-down"
          className={`${classes.imgAsset} ${classes.imgArrowsDown}`}
        />
        <span>Germinate</span>
      </div>
      <div className={`${classes.arrowsDown} ${classes.arrowsDown2}`}>
        <img
          src={imgArrowDown2}
          alt="arrows-down"
          className={`${classes.imgAsset} ${classes.imgArrowsDown}`}
        />
        <span>Non Germinate</span>
      </div>

      <div
        id="board"
        key="board"
        className={`${classes.board}`}
        ref={gameBoard}
      ></div>

      <div
        className={classes.blower}
        style={{ top: gameSettings.pcBlower + "%" }}
      >
        <img
          src={imgBlowAir}
          alt="blower air"
          className={`${classes.imgAsset} ${classes.imgBlowAir} ${
            isShowBlow ? classes.airAnim : ""
          }`}
        />
        <img
          src={imgBlower}
          alt="blower"
          className={`${classes.imgAsset} ${classes.imgBlower}`}
        />
      </div>

      <img
        src={imgTunnels}
        alt="tunnels"
        className={`${classes.imgAsset} ${classes.imgTunnels}`}
      />
      <div className={classes.gameFooter}></div>

      <div
        className={`${classes.unselectable} ${classes.button}`}
        onMouseDown={onButtonDown}
        onTouchStart={onButtonDown}
        onMouseUp={onButtonUp}
        onTouchEnd={onButtonUp}
      >
        <img
          src={imgButtonUp}
          alt="button"
          className={`${classes.imgAsset} ${classes.imgButton}`}
          style={{ display: isShowBlow ? "none" : "block" }}
        />
        <img
          src={imgButtonDown}
          alt="button"
          className={`${classes.imgAsset} ${classes.imgButton}`}
          style={{ display: isShowBlow ? "block" : "none" }}
        />
      </div>

      <div className={classes.scoreBoard}>
        <img
          src={imgScoreBoard}
          alt="score-board"
          className={classes.imgScoreBoard}
        />
        <div className={classes.scoreLeft}>
          <label>Germinate</label>
          <span>{successPc}%</span>
          <span>Goal: {gameSettings.grainsTargetPercent}%</span>
        </div>
        <div className={classes.scoreRight}>
          <label>Seeds left</label>
          <span>{grainsLeft}</span>
          <span>&nbsp;</span>
        </div>
      </div>
      <div className={classes.timesUp}>
        <div className={classes.timesUpCircle}>
          <img src={imgBatchIcon} alt="batch" />
          <span>Batch is over</span>
        </div>
      </div>

      {showSeedX && <SeedXScreen onClose={onSeedXClose} />}
    </div>
  );
};

export default Game;
