import { useEffect } from "react";
import AppHelper from "../../../helpers/app-helper";
import classes from "./window.module.scss";

export interface WindowProps {
  id?: string;
  className?: string;
  children?: any;
  open?: boolean;
  onClose?: () => void;
  unClosable?: boolean;
}

const Window = (props: WindowProps) => {
  useEffect(() => {
    if (props.open) {
      AppHelper.addBodyClass("unscrollable");
    } else {
      AppHelper.removeBodyClass("unscrollable");
    }
    //
    return () => {
      AppHelper.removeBodyClass("unscrollable");
    }
  }, [props.open]);

  const onBgClick = (e: any) => {
    if(!props.unClosable) {
      props.onClose && props.onClose();
    }
  };

  return (
    <div
      className={`window ${classes.window} ${props.className} ${
        props.open ? classes.open : ""
      }`}
      id={props.id}
    >
      <div className={classes.bg} onClick={onBgClick}></div>
      <div className={classes.container}>
        <div className={classes.inner}>{props.children}</div>
      </div>
    </div>
  );
};

export default Window;
