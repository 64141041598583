import classes from "./center.module.scss";

export interface CenterProps {
  id?: string;
  className?: string;
  children?: any;
  type?: "div" | "section";
  height?: string;
  color?: "black" | "white" | "light-gray" | "mid-gray" | "dark-gray" | "green";
  display?: "block" | "inline-block" | "flex" | "none";
  roundCorners?: boolean;
  padding?: string;
}

const Center = (props: CenterProps) => {
  return props.type === "section" ? (
    <section
      className={`${classes.center} ${classes["type-section"]} ${
        classes["display-" + (props.display || "block")]
      } ${classes["color-" + (props.color || "none")]} ${
        props.roundCorners ? classes.roundCorners : ""
      } ${props.className ? props.className : ""}`}
      style={{ height: props.height, padding: props.padding }}
    >
      {props.children}
    </section>
  ) : (
    <div
      className={`${classes.center} ${classes["type-div"]} ${
        classes["display-" + (props.display || "block")]
      } ${classes["color-" + (props.color || "none")]} ${
        props.roundCorners ? classes.roundCorners : ""
      } ${props.className ? props.className : ""}`}
      style={{ height: props.height, padding: props.padding }}
    >
      {props.children}
    </div>
  );
};

export default Center;
