import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import AppHelper from "../../../helpers/app-helper";
import Center from "../../ui/center/center";
import GameLogo from "../../ui/game-logo/game-logo";
import PageBg from "../../ui/page-bg/page-bg";
import Page from "../../ui/page/page";

import imgCloseBt from "../../../assets/icon-close-22x22.svg";
import imgBlower from "../../../assets/instructions-image-804x305.png";
import imgButton from "../../../assets/button-red-up-129x85.svg";

import classes from "./page-instructions.module.scss";
//import ConversionHelper from "../../../helpers/conversion-helper";

const PageInstructions = (props: RouteComponentProps) => {
  const [pageState, setPageState] = useState<string>("how-to-play");

  useEffect(()=>{
    //ConversionHelper.gtmTrackPageView("/instructions","Instructions");
  },[]);

  const onToggleClick = (e: any) => {
    //console.log("onToggleClick");
    setPageState(pageState === "how-to-play" ? "the-prize" : "how-to-play");
  };

  const onCloseClick = (e: any) => {
    //console.log("onCloseClick");
    AppHelper.navigate("/");
  };

  return (
    <Page
      id="page-instructions"
      className={`${classes.instructions}`}
      fullWidth={true}
    >
      <div className={classes.closeBt} onClick={onCloseClick}>
        <img src={imgCloseBt} alt="Close instructions" />
      </div>
      <div className={classes.top}>
        <PageBg />
        <Center type="section" className="page-inner a-center">
          <GameLogo />

          {pageState === "how-to-play" && (
            <>
              {" "}
              <h1>How to play</h1>
              <div className={classes.text}>
                Can you spot the non-germinating seeds from a full batch? Use
                your skill to bring the batch above 90% germinability by
                rejecting non-qualified seeds.
              </div>
              <a className={classes.link} onClick={onToggleClick}>
                ABOUT THE PRIZE
              </a>
            </>
          )}

          {pageState === "the-prize" && (
            <>
              {" "}
              <h1>THE PRIZE</h1>
              <div className={classes.text}>
                Prize winners qualify for a $7000 voucher that can be redeemed
                to sort a seed batch of their choice. This applies after
                purchasing Seed-X’s GeNee™ Sorter.
              </div>
              <a className={classes.link} onClick={onToggleClick}>
                HOW TO PLAY
              </a>
            </>
          )}
        </Center>
        <div className={classes.imgBlower}>
          <img src={imgBlower} alt="blower" />
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={`${classes.bottomInner} page-inner`}>
          <img src={imgButton} alt="button" />
          <div>
            Press this button to move the seeds that will <em>NOT</em> germinate
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PageInstructions;
