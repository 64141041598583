import classes from "./header.module.scss";
import { RouteComponentProps } from "react-router-dom";

const Header = (props:RouteComponentProps) => {
  return (
    <header className={`header ${classes.header} a-center`}>
      <div className={`logo ${classes.logo}`}>
        <a href="/" onClick={(window as any)!.onReactLinkCLick}>
          לוגו
        </a>
      </div>
    </header>
  );
};

export default Header;
