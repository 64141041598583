import classes from "./game-logo.module.scss";
import imgGameLogo from "../../../assets/SeedX-game-logo-564x186.png";
import { CSSProperties } from "react";

export interface GameLogoProps {
  className?: string;
  style?: CSSProperties;
  byHeight?: boolean;
}

const GameLogo = (props: GameLogoProps) => {
  return (
    <div className={`game-logo ${classes.gameLogo} ${props.className} ${props.byHeight ? classes.byHeight : ""}`} style={props.style}>
      <img src={imgGameLogo} alt="Seed-X Sort it out game" />
    </div>
  );
};

export default GameLogo;
