import { RouteComponentProps } from "react-router-dom";
import AppHelper from "../../../helpers/app-helper";
import GameLogo from "../../ui/game-logo/game-logo";
import Center from "../../ui/center/center";
import Page from "../../ui/page/page";

import imgCloseBt from "../../../assets/icon-close-22x22.svg";

import classes from "./page-policy.module.scss";
import { useEffect } from "react";
//import ConversionHelper from "../../../helpers/conversion-helper";

const PagePolicy = (props: RouteComponentProps) => {

  useEffect(()=>{
    //ConversionHelper.gtmTrackPageView("/policy","Terms and Conditions");
  },[]);

  const onCloseClick = (e: any) => {
    //console.log("onCloseClick");
    AppHelper.navigate("/register");
  };

  return (
    <Page id="page-policy" className={`${classes.policy}`} noBg={true}>
      <div className={classes.closeBt} onClick={onCloseClick}>
        <img src={imgCloseBt} alt="Close game policy" />
      </div>

      <div className={classes.top}>
        <Center className="a-center" height={"80%"}>
          <GameLogo byHeight={true} />
          <h1>Terms and Conditions</h1>
        </Center>
      </div>
      <div className={classes.bottom}>
        <section>
          <p>
            <h2>Art 1. Definitions</h2>
            The terms used in the present Term and Conditions will have the
            following meaning:
          </p>
          <p>
            <strong>Contest:</strong> The contest “Sort it Out”
            <br />
            <strong>Winner(s):</strong> Registered participants which have been
            identified following the procedure detailed at article 5.2. below.
            <br />
            <strong>Terms and Conditions:</strong> The present Terms and
            Conditions.
            <br />
            <strong>Organizer:</strong> Seed-X Technologies Inc.
            <br />
            <strong>Organizer Website:</strong>{" "}
            <a href="https://www.seed-x.com" target="_blank">
              www.seed-x.com
            </a>
            <br />
            <strong>Participants:</strong> Registered participants
            <br />
            <strong>Participants Account:</strong> The Participant's account on
            Organizer Website
            <br />
            <strong>Winners:</strong> Potential winners which have claimed their
            prize according to article 5 below.
          </p>
        </section>

        <section>
          <p>
            <h2>Art 2. Contest Organizer</h2>
            2.1. The Contest is organized and carried out by the Organizer as it
            deems fit.
            <br />
            2.2. The Contest’s Participants are obliged to comply with this
            Terms and Conditions.
            <br />
            2.3. The Organizer considers the submission to the Contest as a
            default acceptance of these Terms and Conditions, and by
            participating in the Contest, Participants declare that they agree
            to respect and comply with all provisions, terms and conditions
            found in these Terms and Conditions. In case of non-compliance by a
            Participant with these Terms and Conditions, the Participant will be
            ineligible to participate in the Contest and to receive any benefits
            therefrom, and the Organizer will void the Prize granted to
            Participant (if any), without any compensation or payment.
            <br />
            2.4. The Organizer reserves the right to stop or terminate the
            Contest and to amend these Terms and Conditions, at any time and for
            any or no reason. Any amendment to these Terms and Conditions will
            enter into force upon notice by Organizer.
          </p>
        </section>

        <section>
          <p>
            <h2>Art 3. Right to participate</h2>
            3.1. Any natural person which is at least 18 years old and which has
            registered to participate in the Contest may so participate, with
            the exception of:
            <br />
            (a) representatives (including but not limited to employees) of the
            Organizer; and
            <br />
            (b) relatives of the persons mentioned above (children, parents,
            husband/wife, brothers/sisters).
            <br />
            3.2. If a Participant has been announced as a Winner, they may not
            further participate in Contest or additional Contests. If such a
            Participant is awarded a Prize and then found to have breached this
            clause, the Participant must immediately return any awarded Prize to
            the Organizer. The Organizer has sole discretion to determine if
            this clause has been breached by any Participant.
          </p>
        </section>

        <section>
          <p>
            <h2>Art 4 Contest period &amp; location</h2>
            4.1. The Contest is held from February 2021 and until the Organizer
            determines to terminate the Contest at its sole discretion.
          </p>
        </section>

        <section>
          <p>
            <h2>Art 5 Contest Description</h2>
            5.1. Within the Period of the Contest the Participants shall take
            the following actions:
            <br />
            (a) the Participant shall turn a non-qualified into a qualified
            batch by raising its germinability level to 90% or more (the "
            <strong>Target Score</strong>"), and
            <br />
            (b) a Participant who accomplished the Target Score shall be defined
            as a Winner, as determined by Organizer in its sole good faith
            discretion.
            <br />
            5.2. If the Organizer suspects a certain Participant i of fraud, it
            reserves the right to cancel their participation and will provide a
            notice to that Participant shortly thereafter, at its sole
            discretion and judgement.
          </p>
        </section>

        <section>
          <p>
            <h2>Art 6 Prizes &amp; Value. Award procedure.</h2>
            6.1. Within the Contest, the Organizer will grant the Winner(s) a
            voucher for scanning seeds in an amount of $7,000, using the
            Organizer's GeNee™ Sorter purchased by the Participant or the
            employer of the Participant, in conjunction with Organizer’s AI seed
            scanning solution (the "<strong>Prize</strong>").
            <br />
            6.2. Each GeNee™ Sorter accepts 5 Prizes only.
            <br />
            6.3. The Prize, whether activated or not, will expire on December
            31, 2022.
            <br />
            6.4. The Prize granted within this Contest cannot be redeemed, or
            replaced or exchanged for money or other benefits (irrespective of
            whether the Prize could not have been used due to the use
            limitations set out above or otherwise).
            <br />
            6.5. The Prize is nominal and is not transferable.
            <br />
            6.6. For the actual entry into possession of the Prize, the voucher
            will be delivered electronically to each Winner via Email.
            <br />
            6.7. The decision of the Organizer in respect of any eligibility for
            a Prize, the construction of these Terms and Conditions, or any
            other matter related to the Contest, is final and no correspondence
            will be entered into.
          </p>
        </section>

        <section>
          <p>
            <h2>
              Art 7. Interruption, termination and modification of the Contest
            </h2>
            7.1. The Contest shall terminate at the end of the Contest period
            set forth above, upon which participation in the Contest of the
            Participants shall terminate.
            <br />
            7.2. The Organizer reserves the right to stop the Contest at any
            time and/or change these Terms and Conditions at any time and for
            any or no reason by notice thereof. .
          </p>
        </section>

        <section>
          <p>
            <h2>Art 8. Liability</h2>
            8.1. The Organizer reserves the right to take all necessary measures
            in case of attempt of fraud, abuse or any other attempt that could
            affect the image and the conduct of the Contest or the Organizer.
            <br />
            8.2. The Organizer reserves the right to definitively exclude any
            Participant in the Contest which Participant has not fulfilled
            and/or has not complied with the conditions included in this Terms
            and Conditions.
            <br />
            8.3. In case of fraudulent behaviour, the Organizer reserves the
            right to suspend/cancel at any time the rights and benefits of the
            Participants, without any payment or any compensation. In case the
            fraudulent behaviour is discovered after the award of the prize, the
            Participant shall return to the Organizer the prize or its value and
            any related expenses.
            <br />
            8.4. By participating in this Contest, the participant declares on
            their own responsibility that he has been aware, and agrees that
            they are solely responsible for any non-compliant declaration of
            reality or other non-compliance.
            <br />
            8.5. To the fullest extent permitted by law, the Organizer is
            exempted by the Participant of any liability for all damages
            incurred by the Participant or any other third parties in connection
            with the Contest, including the Prize, irrespective of the nature of
            such damages. In no event will Organizer be liable for any indirect,
            consequential, incidental or punitive damages of any kind and to any
            person.
            <br />
            8.6. To the fullest extent permitted by law, the Organizer assumes
            no responsibility for any:
            <br />
            (i) error, omission, interruption, deletion, defect, delay in
            operation or transmission, communications line failure, theft or
            destruction or unauthorized access to, or alteration of, entries;
            (ii) problems or technical malfunction of any telephone network or
            telephone lines, computer on-line systems, servers, or providers,
            computer equipment, software, failure of any email or entry to be
            received by the Organizer on account of technical problems, human
            error or traffic congestion on the Internet or at any website, or
            any combination thereof, including any injury or damage to
            Participant&#39;s or any other person&#39;s computer relating to or
            resulting from participation in thus Prize draw or downloading any
            materials in this Contest; and (iii) incomplete, inaccurate or
            incorrect participations, as well as for those performed after the
            expiry of the Contest period.
            <br />
            8.7. By participate in the Contest, Participants release and hold
            harmless the Organizer, its parent, subsidiary and affiliated
            companies, respective subsidiaries, directors, officers, employees
            and agents from any and all liability or any injuries, loss or
            damage of any kind arising from or in connection with these Terms
            and Conditions, the Contest or acceptance or use of any Prize won.
            <br />
            8.8. The Organizer reserves the right at its sole discretion to
            cancel, terminate, modify or suspend this Contest and these Terms
            and Conditions.
          </p>
        </section>

        <section>
          <p>
            <h2>Art 9. Personal Data Processing</h2>
            Organizer will be collecting personal data about the Participants in
            accordance with Organizer's privacy policy. Please review the
            Organizer's privacy policy at{" "}
            <a href="https://sorter.seed-x.com/policy" target="_blank">
              www.seed-x.com
            </a>
            . By participating, Participant agree to Organizer's collection and
            entrant of Participant's personal information and acknowledge that
            Participant have read and accepted entrant privacy policy.
          </p>
        </section>

        <section>
          <p>
            <h2>Art 10 Taxes</h2>
            If any taxes apply to the Winners in connection with the prizes
            awarded to them pursuant to the Contest, the Winners will bear such
            taxes in accordance with applicable law and authorize Organizer to
            withhold taxes at source if and to the extent applicable.
          </p>
        </section>

        <section>
          <p>
            <h2>Art 11 Force majeure</h2>
            Events may occur that render the Prize draw itself or the awarding
            of the Prize impossible or onerous due to reasons beyond the
            reasonable control of the Organizer and accordingly the Organizer
            may at its absolute discretion vary or amend the Promo Code (Prize)
            and the Participant agrees that no liability shall attach to the
            Organizer as a result thereof.
          </p>
        </section>

        <section>
          <p>
            <h2>Art 12. Litigation and applicable law</h2>
            12.1. Any disputes arising between the Organizer and the
            Participants in this Contest shall be resolved amicably or, if this
            is not possible, disputes will be resolved solely by the competent
            Tel-Aviv court authorities.
            <br />
            12.2. Any complaints related to the conduct of the Contest will be
            sent to the following address{" "}
            <a href="mailto:info@seed-x.com" target="_blank" rel="noreferrer noopener">
              info@seed-x.com
            </a>
            , within five (5) calendar days of the earlier of the end of the
            Contest and the date the cause for complaint arose. The Organizer
            will not consider any complaints submitted thereafter.
            <br />
            12.3. These Terms and Conditions are governed by Israeli law.
          </p>
        </section>
      </div>
    </Page>
  );
};

export default PagePolicy;
