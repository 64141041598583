import { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import AppHelper from "../../../helpers/app-helper";
import ConversionHelper from "../../../helpers/conversion-helper";
import { GameContext } from "../../contexts/game-context";
import { UserContext } from "../../contexts/user-context";
import Center from "../../ui/center/center";
import Page from "../../ui/page/page";
import Game from "./game/game";

import classes from "./page-game.module.scss";

const PageGame = (props: RouteComponentProps) => {
  const { level, setGameState } = useContext(GameContext);
  const { fullName, email } = useContext(UserContext);

  useEffect(()=>{
    //ConversionHelper.gtmTrackPageView("/game","Game");
  },[]);

  // navigate
  useEffect(() => {
    if (typeof level === "undefined" || level < 0) {
      AppHelper.removeHtmlClass("unscroll");
      AppHelper.navigate("/");
    } else if (!fullName || !email) {
      AppHelper.removeHtmlClass("unscroll");
      AppHelper.navigate("/register");
    } else {
      AppHelper.addHtmlClass("unscroll");
    }
    AppHelper.addHtmlClass("unscroll");
  }, [level, fullName, email]);

  const onGameComplete = (percent: number, targetPercent: number) => {
    //console.log("COMPLETE:",percent,targetPercent);
    setGameState &&
      setGameState({
        percent: percent,
        targetPercent: targetPercent,
        win: percent >= targetPercent,
      });
    
    ConversionHelper.gtmTrackEvent("Game Results: "+(percent >= targetPercent ? "Win" : "Lose"));
    AppHelper.removeHtmlClass("unscroll");
    AppHelper.navigate("/results");
  };

  return (
    <Page id="page-game" className={classes.game} hasBgBlack={true} noBgFrame={true} fullWidth={true}>
      <Center type="section" height="100%" className="page-inner">
        <Game level={level} onComplete={onGameComplete} />
      </Center>
    </Page>
  );
};

export default PageGame;
