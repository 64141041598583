import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import classes from "./website.module.scss";
import Header from "../header/header";
import Footer from "../footer/footer";
import AudioLoop from "../../ui/audio-loop/audio-loop";

import imgAudioUnmuted from "../../../assets/sound-unmuted.svg";
import imgAudioMuted from "../../../assets/sound-muted.svg";
import { UserContext } from "../../contexts/user-context";

const soundBg =
  window.location.href.indexOf("localhost") > 0
    ? "http://sub1.fatlady.org/global/mp3/bg.mp3"
    : "/global/mp3/bg.mp3";

export interface WebsiteProps extends RouteComponentProps {
  children?: any;
  hideHeader?: boolean;
  hideFooter?: boolean;
  color?: "black" | "white" | "light-gray" | "mid-gray" | "dark-gray" | "green";
}

const WebsiteMain = (props: WebsiteProps) => {
  const [soundPlaying, setSoundPlaying] = useState(false);
  //const [soundMute, setSoundMute] = useState(false);
  const { muteSounds, setUserState } = useContext(UserContext);

  useEffect(() => {
    if (typeof window !== "undefined" && props && props.history) {
      (window as any).reactRouterHistoryPush = props.history.push;
      (window as any).onReactLinkCLick = onLinkClick;
    }
  }, []);

  const onLinkClick = (e: any) => {
    let href = e.currentTarget.getAttribute("href");
    if (href && href.indexOf("/") === 0) {
      e.preventDefault();
      props.history.push(href);
    }
  };

  const onSoundPlay = () => {
    setSoundPlaying(true);
  };

  return (
    <div
      id="website"
      className={`${classes.website} ${
        props.hideHeader ? classes.hideHeader : ""
      } ${props.hideFooter ? classes.hideFooter : ""} ${
        props.color ? classes["color-" + props.color] : ""
      }`}
    >
      <Header {...props} />
      <div className={classes.page}>
        <div className={classes.headerPlaceholder}></div>
        <div className={classes.inner}>{props.children}</div>
        <div className={classes.footerPlaceholder}></div>
        <Footer {...props} />
      </div>
      <div
        className={classes.audioButton}
        style={soundPlaying ? {} : { opacity: 0.3 }}
      >
        {muteSounds ? (
          <img
            src={imgAudioMuted}
            alt="Unmute sound"
            onClick={() => {
              setUserState && setUserState({ muteSounds: false });
            }}
          />
        ) : (
          <img
            src={imgAudioUnmuted}
            alt="Mute sound"
            onClick={() => {
              setUserState && setUserState({ muteSounds: true });
            }}
          />
        )}
      </div>
      <AudioLoop
        id="page-sound"
        src={soundBg}
        autoplay={true}
        listenToDocument={true}
        muted={muteSounds}
        volume={0.5}
        onInitPlay={onSoundPlay}
      />
    </div>
  );
};

export default WebsiteMain;
