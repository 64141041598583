import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import AppHelper from "../../../helpers/app-helper";
import {
  validateEmail,
  validateEmpty,
  validateFullName,
} from "../../../helpers/validations";
import {
  GameContext,
  GameLevel,
  gameLevelSettings,
} from "../../contexts/game-context";
import ConversionHelper from "../../../helpers/conversion-helper";
import { UserContext } from "../../contexts/user-context";
import Button from "../../ui/button/button";
import Center from "../../ui/center/center";
import Input from "../../ui/input/input";
import Page from "../../ui/page/page";
import Window from "../../ui/window/window";

import classes from "./page-register.module.scss";
import { getLS, setLS } from "../../../helpers/localStorage";

const REGISTER_FORM_KEY:string = "register-form";

const PageRegister = (props: RouteComponentProps) => {
  const lsData:any = getLS(REGISTER_FORM_KEY) || {};
  const [formValues, setFormValues] = useState<any>(lsData || {});
  const { fullName, email, company, country, setUserState } = useContext(
    UserContext
  );
  const { level, setLevel } = useContext(GameContext);
  const [isLevelWindowOpen, setIsLevelWindowOpen] = useState<boolean>(false);
  const [windowUnclosable, setWindowUnclosable] = useState<boolean>(false);

  useEffect(() => {
    //ConversionHelper.gtmTrackPageView('/register','Registration');
  }, []);

  useEffect(() => {
    if (fullName && email && company && country) {
      if (typeof level !== "undefined" && level > -1) {
        AppHelper.navigate("/game");
      } else {
        setWindowUnclosable(true);
        setIsLevelWindowOpen(true);
      }
    }
  }, [fullName, email, company, country, level]);

  const onFieldChange = (
    name: string,
    value: string | number,
    element?: any
  ) => {
    //console.log('onFieldChange',name, value);
    let data: any = { ...formValues };
    data[name] = value;
    setFormValues(data);
    setLS(REGISTER_FORM_KEY,data);
  };

  const onRegister = (e: any) => {
    e.preventDefault();
    //console.log("register", formValues);
    setUserState && setUserState(formValues);
    ConversionHelper.gtmTrackEvent("Registration submit");
    ConversionHelper.addConversion();
    //AppHelper.navigate("/game");
    //setIsLevelWindowOpen(true);
  };

  const onPolicyClick = (e: any) => {
    e.preventDefault();
    AppHelper.navigate("/policy");
  };

  const onLevelClick = (level: GameLevel) => {
    setLevel && setLevel(level);
    ConversionHelper.gtmTrackEvent("Level Select: " + GameLevel[level]);
    AppHelper.navigate("/game");
  };

  return (
    <Page id="page-register" className={classes.register}>
      <Center type="section" className="a-center">
        <h1>REGISTER TO PLAY &amp; WIN</h1>
        <div className={classes.text}>Hurry! The clock is ticking</div>

        <form className={classes.form} onSubmit={onRegister}>
          <Input
            type="text"
            name="fullName"
            value={fullName || lsData.fullName}
            label="Name"
            required={true}
            onChange={onFieldChange}
            validations={[validateEmpty, validateFullName]}
            display="block"
            slimMargins={true}
          />
          <Input
            type="email"
            name="email"
            value={email || lsData.email}
            label="Work email"
            required={true}
            onChange={onFieldChange}
            validations={[validateEmpty, validateEmail]}
            display="block"
            slimMargins={true}
          />
          <Input
            type="text"
            name="company"
            value={company || lsData.company}
            label="Company"
            required={true}
            onChange={onFieldChange}
            validations={[validateEmpty]}
            display="block"
            slimMargins={true}
          />
          <Input
            type="text"
            name="country"
            value={country || lsData.country}
            label="Country"
            required={true}
            onChange={onFieldChange}
            validations={[validateEmpty]}
            display="block"
            slimMargins={true}
          />
          <Button
            type="submit"
            label="Start"
            display="flex"
            color="dark-green"
            textSize="big"
          />
        </form>
        <div className={classes.text2}>
          By submitting my details,
          <br />I agree to{" "}
          <a href="#" onClick={onPolicyClick}>
            The game policy
          </a>
        </div>
      </Center>
      <Window
        open={isLevelWindowOpen}
        onClose={() => {
          setIsLevelWindowOpen(false);
        }}
        unClosable={windowUnclosable}
      >
        <h3>Select Difficulty</h3>
        <Center type="div" padding="1rem 0 0">
          <Button
            label={gameLevelSettings[GameLevel.Easy].label}
            label2={
              "Start at " +
              gameLevelSettings[GameLevel.Easy].grainsGoodPercent +
              "% Germination"
            }
            color="light-green"
            display="flex"
            textSize="big"
            onClick={() => onLevelClick(GameLevel.Easy)}
          />
          <Button
            label={gameLevelSettings[GameLevel.Medium].label}
            label2={
              "Start at " +
              gameLevelSettings[GameLevel.Medium].grainsGoodPercent +
              "% Germination"
            }
            color="mid-green"
            display="flex"
            textSize="big"
            onClick={() => onLevelClick(GameLevel.Medium)}
          />
          <Button
            label={gameLevelSettings[GameLevel.Expert].label}
            label2={
              "Start at " +
              gameLevelSettings[GameLevel.Expert].grainsGoodPercent +
              "% Germination"
            }
            color="dark-green"
            display="flex"
            textSize="big"
            onClick={() => onLevelClick(GameLevel.Expert)}
          />
        </Center>
      </Window>
    </Page>
  );
};

export default PageRegister;
