import React, { useState } from "react";
import { getLS, setLS } from "../../helpers/localStorage";

export const UserContext = React.createContext<IUser>({});
export const USER_CONTEXT_LS_KEY:string = "USER_CONTEXT";

export interface UserContextProps {
  children?: any;
}

export interface IUser {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  country?: string;
  company?: string;
  wonSaved?: boolean;
  lostSaved?: boolean;
  muteSounds?: boolean;
  setUserState?: (user: IUser) => void;
}

const UserContextProvider = (props: UserContextProps) => {
  const givenUser: IUser = getLS(USER_CONTEXT_LS_KEY);
  //console.log('givenUser',givenUser);

  const [user, setUser] = useState<IUser>({
    firstName: givenUser ? givenUser.firstName || "" : "",
    lastName: givenUser ? givenUser.lastName || "" : "",
    fullName: givenUser ? givenUser.fullName || "" : "",
    email: givenUser ? givenUser.email || "" : "",
    country: givenUser ? givenUser.country || "" : "",
    company: givenUser ? givenUser.company || "" : "",
    muteSounds: givenUser ? givenUser.muteSounds || false : false,
    wonSaved: givenUser ? givenUser.wonSaved || false : false,
    lostSaved: givenUser ? givenUser.lostSaved || false : false,
  });

  const setUserState = (userObj: any) => {
    if(userObj.fullName && !userObj.firstName) {
      userObj.firstName = userObj.fullName.substring(0,userObj.fullName.indexOf(" "));
      userObj.lastName = userObj.fullName.substring(userObj.fullName.indexOf(" ")+1);
    } else if (userObj.firstName && !userObj.fullName) {
      userObj.fullName = userObj.firstName + " " + (userObj.lastName || "");
    }
    let newUser: IUser = { ...user, ...userObj };
    setUser(newUser);
    setLS(USER_CONTEXT_LS_KEY, newUser);
  };

  return (
    <UserContext.Provider value={{ ...user, setUserState: setUserState }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
