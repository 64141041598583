export default class ConversionHelper {
  // GTM
  static gtmTrackPageView = (path: string, title: string) => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: "Pageview",
      pagePath: path,
      pageTitle: "Seed-X Sort It Out Game - "+title,
    });
  };

  static gtmTrackEvent = (action:string, label?:string, value?:number) => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: "GameEvent",
      eventCategory: "Game",
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    });
  };

  // OTHER
  static addConversion = () => {
    ConversionHelper.addLinkedInPixel("2481281", "3637170");
    ConversionHelper.addGoogleAdsPixel("622790318", "6251CLnnqPYBEK6N_KgC");
  };

  static addLinkedInPixel = (partnerId: string, convId: string) => {
    const url = `//px.ads.linkedin.com/collect/?pid=${partnerId}&conversionId=${convId}&fmt=gif`;
    ConversionHelper.addPixel("linkedin-pixel", url);
  };

  static addGoogleAdsPixel = (convId: string, convLabel: string) => {
    const url = `//www.googleadservices.com/pagead/conversion/${convId}/?label=${convLabel}&script=0`;
    ConversionHelper.addPixel("linkedin-pixel", url);
  };

  static addPixel = (id: string, url: string) => {
    const image = new Image(1, 1);
    image.id = id;
    image.alt = id;
    image.style.display = "block";
    image.style.position = "absolute";
    image.style.left = "-10px";
    image.style.top = "-10px";
    image.style.opacity = "0";
    image.style.zIndex = "-1";
    image.src = url;
    document && document.body.appendChild(image);
  };
}
