import { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import AppHelper from "../../../helpers/app-helper";
//import ConversionHelper from "../../../helpers/conversion-helper";
import {
  GameContext,
} from "../../contexts/game-context";
import Button from "../../ui/button/button";
import Center from "../../ui/center/center";
import GameLogo from "../../ui/game-logo/game-logo";
import Page from "../../ui/page/page";
import GameImage from "../../../assets/game-phone-642x727.png";

import classes from "./page-home.module.scss";

const PageHome = (props: RouteComponentProps) => {
  const { level, setLevel } = useContext(GameContext);

  useEffect(() => {
    //ConversionHelper.gtmTrackPageView("/","Home");
    setLevel && setLevel(-1);
  }, []);

  const onNextClick = (e: any) => {
    AppHelper.navigate("/register");
  };

  const onInstructionsClick = (e: any) => {
    AppHelper.navigate("/instructions");
  };

  return (
    <Page id="page-home" className={classes.home} greenBg={true} gridZIndex={1}>
      <Center type="div" className="a-center">
        <div className={classes.preTitle}>
          Ready to have some fun? Ready to win credit to sort your seed batches?
        </div>
        <h1>Play with us!</h1>
        <div className={classes.mainImage}>
          <img src={GameImage} alt="Game in phone" />
          <div className={classes.gradient}>
            <GameLogo className={classes.logo} />
            <div className={classes.text}>
              Turn a non-qualified into a qualified batch by raising its
              germinability level to over 90% and WIN!
            </div>
          </div>
        </div>
        <a className={classes.link} onClick={onInstructionsClick}>
          HOW TO PLAY?
        </a>
        <Button
          label="Play now"
          color="yellow"
          display="flex"
          textSize="big"
          onClick={onNextClick}
        />
      </Center>
    </Page>
  );
};

export default PageHome;
