import { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import APIHelper from "../../../helpers/api-helper";
import AppHelper from "../../../helpers/app-helper";
//import ConversionHelper from "../../../helpers/conversion-helper";
import { GameContext, GameLevel } from "../../contexts/game-context";
import { UserContext } from "../../contexts/user-context";
import BlackSign from "../../ui/black-sign/black-sign";
import Button from "../../ui/button/button";
import Center from "../../ui/center/center";
import GameLogo from "../../ui/game-logo/game-logo";
import Page from "../../ui/page/page";

import sorterImage from "../../../assets/SeedX-sorter-machine-380x306.png";

import classes from "./page-result.module.scss";

const PageResult = (props: RouteComponentProps) => {
  const { level, percent, targetPercent, win } = useContext(GameContext);
  const {
    fullName,
    email,
    company,
    country,
    wonSaved,
    lostSaved,
    setUserState,
  } = useContext(UserContext);

  const saveData = async () => {
    const data: any = {
      name: fullName,
      email: email,
      country: country,
      company: company,
      level: GameLevel[level || 0],
      won: win ? "Yes" : "No",
    };
    //console.log("date",dateStr);
    //alert("SEND: " + JSON.stringify(data));
    await APIHelper.submitGame(data)
      .then((data: any) => {
        let newState: any = {};
        if (win) {
          newState.wonSaved = true;
        } else {
          newState.lostSaved = true;
        }
        setUserState && setUserState(newState);
      })
      .catch((error: Error) => {
        console.warn("FETCH ERROR", error);
      });
  };

  useEffect(() => {
    //ConversionHelper.gtmTrackPageView("/results","Results: "+(win ? "Win" : "Lose"));
    if ((win && !wonSaved) || (!win && !lostSaved && !wonSaved)) {
      saveData();
    }
  }, []);

  // navigate
  useEffect(() => {
    if (typeof level === "undefined" || level < 0) {
      AppHelper.navigate("/");
    } else if (!fullName || !email || !company || !country) {
      AppHelper.navigate("/register");
    }
  }, [level, fullName, email]);

  const onReplayClick = (e: any) => {
    AppHelper.navigate("/");
  };

  return (
    <Page
      id="page-result"
      className={`${classes.result} ${win ? classes.win : classes.lose}`}
    >
      <Center type="section" className="a-center">
        {win ? (
          // WIN
          <>
            <BlackSign className={classes.blackSign}>
              <h1 className={win ? classes.h1win : classes.h1lose}>
                Batch is qualified!
              </h1>
              <div className={classes.resultValues}>
                <div>YOU MANAGED TO SORT</div>
                <div>{percent || 0}%</div>
                <div>Germination rate</div>
              </div>
            </BlackSign>

            <h2>Congrats on your great performance!</h2>
            <img
              className={classes.sorterImage}
              src={sorterImage}
              alt="Seed-X Sorter™"
            />

            <div className={classes.textWhite}>
              We'll be in touch shortly with your voucher worth $7000
            </div>
            <Button
              type="a"
              target="_blank"
              label="Meet the GeNee™ Sorter"
              display="flex"
              color="white"
              textSize="small"
              case="none"
              href="https://www.seed-x.com/product/sorter/?source=game"
            />
          </>
        ) : (
          // LOSE
          <>
            <GameLogo className={classes.pageLogo} />
            <BlackSign className={classes.blackSign}>
              <h1 className={win ? classes.h1win : classes.h1lose}>
                Batch not Qualified!
              </h1>
              <div className={classes.resultValues}>
                <div>YOU MANAGED TO SORT</div>
                <div>{percent || 0}%</div>
                <div>Germination rate</div>
              </div>
            </BlackSign>

            <Button
              type="button"
              label="Play again"
              display="flex"
              textSize="big"
              color="dark-green"
              onClick={onReplayClick}
            />
            <Button
              type="a"
              target="_blank"
              label="Meet the GeNee™ Sorter"
              display="flex"
              color="white"
              textSize="small"
              case="none"
              href="https://www.seed-x.com/product/sorter/?source=game"
            />
          </>
        )}
      </Center>
    </Page>
  );
};

export default PageResult;
