import { useRef, useState } from "react";
import classes from "./input.module.scss";

export interface InputProps {
  type: "text" | "number" | "email" | "tel" | "password" | "url" | "date";
  name: string;
  value?: string | number;
  id?: string;
  className?: string;
  label: string;
  required?: boolean;
  display?: "block" | "inline-block" | "none";
  onChange?: (name: string, value: string | number, element?: any) => void;
  validations?: any[];
  slimMargins?: boolean;
}

const Input = (props: InputProps) => {
  const inpRef: any = useRef();
  const [value, setValue] = useState(props.value);
  const [focus, setFocus] = useState(false);
  const [invalid, setInvalid] = useState<any>(false);
  const id: string = props.id || "inp-" + props.name;
  const isEmpty: boolean = !focus && (!value || !value.toString().length);

  const onChange = (e: any) => {
    setValue(e.target.value);
    validate(e.target.value);
    if (props.onChange && typeof props.onChange === "function") {
      props.onChange(props.name, value ? value : "", inpRef);
    }
  };

  const validate = (val: string | number | undefined, mark?: boolean) => {
    if (props.validations && props.validations.length) {
      let err: string = "";
      props.validations.forEach((f: any) => {
        if (!err.length && typeof f === "function") {
          let invalid: boolean | string = f(val, props.label);
          if (typeof invalid === "string") {
            err = invalid;
          }
        }
      });
      mark &&
        inpRef.current &&
        inpRef.current.setCustomValidity(err ? err : "");
      setInvalid((mark ? true : false) && err);
    }
  };

  return (
    <div
      className={`${classes.input} ${props.className || ""} ${
        isEmpty ? classes.empty : ""
      } ${classes["display-" + (props.display || "block")]} ${
        props.slimMargins ? classes.slimMargins : ""
      }`}
    >
      <label htmlFor={id}>
        {props.label}
        {props.required ? <span> *</span> : undefined}
      </label>
      <input
        type={props.type}
        id={id}
        name={props.name}
        value={value}
        required={props.required}
        ref={inpRef}
        onFocus={(e: any) => {
          setFocus(true);
          setInvalid(true);
        }}
        onBlur={(e: any) => {
          setFocus(false);
          validate(e.target.value, true);
        }}
        onChange={onChange}
        onKeyUp={onChange}
      ></input>
      {invalid && invalid.length && (
        <div className={classes.error}>{invalid}</div>
      )}
    </div>
  );
};

export default Input;
